import { ToastMsgType } from ".";
import { getDateFormat } from "../reusable-functions/dateTimeFormatFunction";
import { userToBeCalled } from "../text-constants";

/**
 * Check whether array has value
 */
export const checkArrayHasValues = (arrayObject: any) => {
  let i = 0;
  Array.isArray(arrayObject) && arrayObject.forEach(() => (i = i + 1));
  return i;
};

/**
 * Showing the toast message
 * setMsg is a function destructured from
 */
export const showToast = (setMsg: any, message: string | number, type: any) => {
  const messageValues: ToastMsgType = {
    show: true,
    type: type,
    msg: message,
    errorType: "default",
  };
  setMsg(messageValues);
};

/**check 2 array objects are equal  */

export const checkTwoArrayObjectsEqual = (
  array1: any[] = [],
  array2: any[] = [],
  dataAttribute1?: string,
  dataAttribute2?: string
) => {
  if (array1.length !== array2.length) {
    return false;
  }
  const filteredNewValues = array1.filter(
    (addedTag: any) =>
      !array2.some(
        (existingTag: any) =>
          existingTag[dataAttribute1 || "name"] === addedTag[dataAttribute2 || "name"]
      )
  );

  if (filteredNewValues.length) {
    return false; // two arrays are not equal
  } else {
    return true;
  }
};

/**
 * check for value
 */

export const checkForValue = (data: any, noDataValue?: any) => {
  if (data >= 0) {
    return data;
  } else {
    return noDataValue || "$";
  }
};

/**get currency type */

export const getCurrencyType = (countryId: string) => {
  switch (countryId) {
    case "TW":
      return "TWD";
    case "AU":
      return "AUD";
    case "US":
      return "USD";
    default:
      return "";
  }
};

/**status list */

export const status = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "InActive" },
];

/**get status text */

export const getStatusText = (statusType: string) => {
  if (statusType === "Active") {
    return "Active";
  } else {
    return "Inactive";
  }
};

//disabling default onEnter click

export function onKeyDown(e: any) {
  if ((e.code === "Enter" || e.code === "NumpadEnter") && !e.shiftKey && e.which) {
    e.preventDefault();
  }
}

/**manipulate dom by javascript */

export function changeColor(elementId: string, color: string) {
  document.getElementById(elementId).style.backgroundColor = color;
  document.getElementById(elementId).style.borderRadius = "6px";
}

export const sortNumbers = (
  value: any,
  dataAttribute1: string,
  dataAttribute2: string
) => {
  return value.sort((a: any, b: any) => a[dataAttribute1] - b[dataAttribute2]);
};

export const getTimezones = (countryId: string) => {
  if (countryId === "TW") {
    return "(Taipei Standard Time)";
  } else if (countryId === "AU") {
    return "(Australian Eastern Time)";
  } else {
    return "(Pacific Time)";
  }
};

/**check for existing pronoun from the static list */

export const checkPronounExist = (selectedPronoun: string) => {
  return userToBeCalled.find(
    (pronoun: any) =>
      pronoun.value.toLowerCase() === selectedPronoun?.toString()?.toLowerCase()
  );
};

/**getting signed up date based on userType */

export function getSignupDateLabel(userType: string) {
  if (userType === "guide") {
    return "Signed Up Date - As Guide";
  } else {
    return "Signed Up Date - As Explorer";
  }
}

export function getSingupDate(values: any, userType: string) {
  if (userType === "guide") {
    return getDateFormat(values.guideSignUpDate) || "-";
  } else {
    return getDateFormat(values.explorerSignUpDate) || "-";
  }
}

/**used in edit guide/explorer final payload to check for the formdata object */
export function checkFormHasFile(formData: any) {
  let i = 0;
  formData.forEach(() => (i = i + 1));
  return i;
}

/**converting array of object into object */
export function convertArrayToObject(
  values: any,
  keyAttribute?: string,
  valueAttribute?: string
) {
  return values?.reduce((obj: any, item: any) => {
    obj[keyAttribute || item.name] = item[valueAttribute || "data"];
    return obj;
  }, {});
}

export function getSearchPlaceholderText(index: number) {
  switch (index) {
    case 0:
      return "Search Class ID/Class Title/Guide Name/Guide Email"
    case 1:
      return "Search First Name/Last Name/Email/Username";
    case 2: 
    return "Search Booking ID/Class ID/Guide ID/Explorer ID"
    default:
      return "Search";
  }
}

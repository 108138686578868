// this function handles functionalities related to view each class page

import { getDateFormat } from "../../dateTimeFormatFunction";
import { getCountryName } from "../../getCountryName";

export const fetchedViewClassData = (data: any) => {
  return [
    { label: "Class ID", data: data.classId || "-" },
    {
      label: "Guide Email",
      data: data.guideEmail || "-",
    },
    {
      label: "Guide First Name",
      data: data.guideFirstName || "-",
    },
    {
      label: "Guide Last Name",
      data: data.guideLastName || "-",
    },
    {
      label: "Guide ID",
      data: data.guideId || "-",
    },
    {
      label: "Guide Phone Number",
      data: data.guidePhone || "-",
    },
    {
      label: "Is Class Active",
      data: data.isActive ? "Yes" : "No",
    },
    {
      label: "Is it a repeated class (Yes / No)",
      data: data.repeatedClass?.isRepeted ? "Yes" : "No",
    },
    {
      label: "Class Title",
      data: data.classTitle || "-",
    },
    {
      label: "Class repeated from (Class ID of parent class - If repeated)",
      data: data.repeatedClass?.repeatedFrom || "-",
    },
    {
      label: "Main Category Name",
      data: data.categoryName || "-",
    },
    {
      label: "Main Category ID",
      data: data.categoryId || "-",
    },
    {
      label: "Guide Experience",
      data: data.guideExperience || "-",
    },
    {
      label: "Class Difficulty Level",
      data: data.classDifficultyLevel || "-",
    },
    {
      id: "id-tag",
      label: "Tags for the class",
      data: data.taggings?.map((tag: any) => ({ ...tag, prefix: "#" })) || "-",
    },
    {
      label: "About the Class",
      data: data.classDesc || "-",
    },
    {
      label: "Schedule Start Date",
      data: getDateFormat(data.schedule?.startTimestamp) || "-",
    },
    {
      label: "Schedule End Date",
      data: getDateFormat(data.schedule?.endTimestamp) || "-",
    },
    {
      label: "Sign-up cut-off time",
      data: data.cutOffTime || "-",
    },
    {
      label: "Price - Per Class Per Person (Guide Price)",
      data: data.price?.pricePerClassPerPerson || "-",
    },
    {
      label: "Price - Service Charge",
      data: data.price?.serviceCharge || "-",
    },
    {
      label: "Price - Tax",
      data: data.price?.tax || "-",
    },
    {
      label: "Price - Total",
      data: data.price?.total || "-",
    },
    {
      label: "Price - Price Currency",
      data: data.price?.priceCurrency || "$",
    },
    {
      label: "Minimum explorers",
      data: data.minExplorers || "-",
    },
    {
      label: "Maximum Explorers",
      data: data.maxExplorers || "-",
    },
    {
      label: "Class Created Date",
      data: getDateFormat(data.createdOn) || "-",
    },
    {
      label: "Class Updated Date",
      data: getDateFormat(data.updatedOn) || "-",
    },
    {
      label: "Language",
      data: data.languageKnown || "-",
    },
    {
      label: "What should the explorer bring?",
      data: data.additionalReqComments || "-",
    },
    {
      label: "Cancellation reason",
      data: data.cancellation?.reasonDisplay || data.cancellation?.otherReason || "-"
    },
    {
      label: "Cancelled on",
      data: getDateFormat(data.cancellation?.cancelledOn) || "-"
    }
  ];
};

export const fetchedViewClassLocationData = (locationData: any = {}) => {
  return [
    {
      label: "Apartment number / Building name",
      data: locationData.apartmentNumberOrBuildingName || "-",
    },
    {
      label: "Address Line 1",
      data: locationData.addressLine1 || "-",
    },
    {
      label: "City",
      data: locationData.cityName || "-",
    },
    {
      label: "Country",
      data: getCountryName(locationData.countryId) || "-",
    },
    {
      label: "Zip Code",
      data: locationData.zipCode || "-"
    },
    {
      label: "Additional Location Details",
      data: locationData.directions || "-",
    },
    {
      label: "Latitude",
      data: locationData.loc?.coordinates[1] || "-",
    },
    {
      label: "Longtitude",
      data: locationData.loc?.coordinates[0] || "-",
    },
    {
      label: "Location is visible to all explorers",
      data: locationData.makeLocationPublic === "true" ||
      locationData.makeLocationPublic === true
        ? "Yes"
        : "No"
    },
    {
      label: "Location Images",
      data: locationData.locationImages || []
    },
  ];
};

export const getOtherTotalImages = (otherImages: any[] = []) => {
  const extraEmptyImageObject: any[] = [];
  for (let i = 0; i < 4 - otherImages?.length; i++) {
    extraEmptyImageObject.push({ url: "", alt: "image" });
  }
  return [...otherImages, ...extraEmptyImageObject];
};
